<template>
    <div class="flowCThree">
        <div class="fcTBox">
            <!-- 左区域 -->
            <div class="fcTLeft">
                <!-- 文字 -->
                <div class="fcTTexts wow animate__animated animate__fadeInDown">
                    <p class="fcTBig">商机雷达 智能锁客</p>
                    <p class="fcTSmall">授权获取意向客户微信信号、微信昵称、手机号码等信息，客户容易接受 ，信息获取成功率高。</p>
                    <p class="fcTSmall">访客点击及时提醒，轻松捕获客户轨迹和浏览喜好，大数据算法锁定高意向 客户快速跟进</p>
                </div>
                <!-- 图片 -->
                <div class="fcTImgs">
                    <img
                        src="https://iv.vu818.com/img/img4-1.png"
                        alt=""
                        class="fcTImg wow animate__animated animate__fadeInUp"
                        data-wow-delay="0.2s"
                    />
                </div>
            </div>
            <!-- 右区域 -->
            <div class="fcTRight">
                <!-- 图片 -->
                <div class="fcTImgs">
                    <img src="https://iv.vu818.com/img/img4-2.png" alt="" class="fcTImg wow animate__animated animate__fadeInDown" />
                </div>
                <!-- 文字 -->
                <div class="fcTTexts wow animate__animated animate__fadeInUp" data-wow-delay="0.2s">
                    <p class="fcTBig">IM通讯 沟通高效</p>
                    <p class="fcTSmall">推送信息给所有访客，通过微信服务通知提醒首屏展示，无需添加微信好友或关注公众号， 实时与访客建立沟通；</p>
                    <p class="fcTSmall">遇大型推广活动/朋友圈广告投放，可设置多个推广坐席接待信息发送， 完成推广访客接待及高效客户转化；</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FlowCThree',
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: 'ease-in-sine',
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: 'wow',
            animateClass: 'animate__animated',
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.flowCThree {
    width: 100%;
    height: 700px;
    background-color: #eee;
    .fcTBox {
        width: 1280px;
        height: 100px;
        margin: 0 auto;
        // 左区域
        .fcTLeft {
            width: 45%;
            height: 80%;
            float: left;
            // 文字
            .fcTTexts {
                width: 100%;
                height: 150px;
                margin-top: 20%;
                text-align: center;
                .fcTBig {
                    width: 100%;
                    height: 50px;
                    line-height: 50px;
                    font-size: 30px;
                    font-weight: 900;
                }
                .fcTSmall {
                    width: 100%;
                    height: 50px;
                    line-height: 25px;
                }
            }
            // 图片
            .fcTImgs {
                width: 100%;
                height: 400px;
                margin-top: 20px;
                .fcTImg {
                }
            }
        }
        // 右区域
        .fcTRight {
            width: 45%;
            height: 80%;
            float: right;
            // 图片
            .fcTImgs {
                width: 100%;
                height: 400px;
                margin-top: 20px;
                .fcTImg {
                }
            }
            // 文字
            .fcTTexts {
                width: 100%;
                height: 150px;
                margin-top: 20px;
                text-align: center;
                .fcTBig {
                    width: 100%;
                    height: 50px;
                    line-height: 50px;
                    font-size: 30px;
                    font-weight: 900;
                }
                .fcTSmall {
                    width: 100%;
                    height: 50px;
                    line-height: 25px;
                }
            }
        }
    }
}
</style>
